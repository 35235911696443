<template>
    <div class="page-sign-in">
        <app-branding-logo v-if="!withoutBranding" />

        <div class="form" v-if="show_mfa_code_form">
            <h2 class="heading">Enter One-Time Password</h2>

            <app-loader v-if="loading" />
            <app-error v-model="errors.show" :message="errors.message" />

            <p>Enter the code you received via app</p>

            <app-input
                v-focus

                v-model="MFACode"

                :error="errors.fields.MFACode"

                @change="errors.fields.MFACode = null"
                @onenter="signInWithMFACode"
            />

            <div class="actions">
                <button class="btn btn-primary" @click="signInWithMFACode">Send</button>
            </div>
        </div>

        <div class="form" v-else>
            <h2 class="heading">Sign in</h2>

            <app-loader v-if="loading" />
            <app-error v-model="errors.show" :message="errors.message" />

            <app-input
                v-model="auth.login"

                label="Username"
                type="email"

                :error="errors.fields.login"

                @change="errors.fields.login = null"
                @onenter="signIn"
            />

            <app-input
                v-model="auth.pass"

                label="Password"
                type="password"

                :error="errors.fields.pass"

                :with-toggle-password-visibility="true"

                @change="errors.fields.pass = null"
                @onenter="signIn"
            />

            <div class="actions">
                <button class="btn btn-primary" @click="signIn">Sign in</button>

                <template v-if="is_google_auth_enabled">
                    <p>or</p>

                    <button class="btn btn-sign-in-with-google" @click="signInWithGoogle"><span>Sign in with Google</span></button>
                </template>

                <router-link :to="forgot_password_link">Forgot password?</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appBrandingLogo from '@/components/app-branding-logo'

import UserDetailsValidator from '@/validators/user-details-validator'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appInput,
        appBrandingLogo,
    },

    props: {
        withoutBranding: { type: Boolean, default: true }
    },

    data() {
        return {
            auth: {
                login: process.env.VUE_APP_TEST_ADMIN_AUTH_LOGIN || '',
                pass:  process.env.VUE_APP_TEST_ADMIN_AUTH_PASS  || '',
            },

            params: {},

            show_mfa_code_form: false,

            MFACode: null,

            errors: {
                show: false,
                message: '',

                fields: {},
            },

            loading: false,
        }
    },

    methods: {
        signIn() {
            const values = {
                login: {
                    value: this.auth.login,
                    message: 'Please, enter username',
                },

                pass: {
                    value: this.auth.pass,
                    message: 'Please, enter password',
                },
            }

            if (this.validation(values)) {
                this.loading = true

                const params = {
                    IdentityProvider: 'LOCAL',

                    AuthID: this.auth.login,
                    AuthCode: this.auth.pass,

                    IncludeRoles: true,
                }

                this.params = params

                this.$store.dispatch('AuthCheck', params)
                    .then(() => {
                        this.loading = false

                        this.$router.push({ name: 'main-dashboard' })
                    })
                    .catch(error => {
                        this.loading = false

                        if (error.response.status == 412) {
                            this.errors.show = false
                            this.errors.message = ''

                            this.showMFACodeForm()
                        } else {
                            this.errors.show = true
                            this.errors.message = errMessage(error)
                        }
                    })
            }
        },

        signInWithGoogle() {
            this.$store.dispatch('googleSignIn')
                .then(GoogleUser => {
                    this.loading = true

                    const params = {
                        IdentityProvider: 'GOOGLE',

                        AuthID: GoogleUser.getId() || 'anonymous',
                        AuthNonce: GoogleUser.getAuthResponse().id_token,

                        IncludeRoles: true,
                    }

                    this.params = params

                    this.$store.dispatch('AuthCheck', params)
                        .then(() => {
                            this.loading = false

                            this.$router.push({ name: 'main-dashboard' })
                        })
                        .catch(error => {
                            this.loading = false

                            if (error.response.status == 412) {
                                this.showMFACodeForm()
                            } else {
                                this.errors.show = true
                                this.errors.message = errMessage(error)
                            }
                        })
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)
                })
        },

        signInWithMFACode() {
            const values = {
                MFACode: {
                    value: this.MFACode,
                    message: 'Please, enter the code you received via app',
                },
            }

            if (this.validation(values)) {
                this.loading = true

                const params = {
                    ...this.params,

                    MFACode: this.MFACode,
                }

                this.$store.dispatch('AuthCheck', params)
                    .then(() => {
                        this.loading = false

                        this.$router.push({ name: 'main-dashboard' })
                    })
                    .catch(error => {
                        this.loading = false

                        this.errors.show = true
                        this.errors.message = errMessage(error)
                    })
            }
        },

        validation(values) {
            let is_valid = true

            this.errors.fields = {}

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key].message)) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        showMFACodeForm() {
            this.show_mfa_code_form = true
        },
    },

    computed: {
        ...mapGetters([
            'is_google_auth_enabled',
        ]),

        forgot_password_link() {
            return {
                name: 'forgot-password',
                ...(this.withoutBranding ? {} : { query: { branding: 'true' } }),
            }
        },
    },
}
</script>

<style lang="scss">
.page-sign-in {
    .app-error {
        margin-bottom: 24px;
    }
}
</style>